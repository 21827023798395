@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GilbertBold";
  src: local("GilbertBold"), url("../fonts/Gilbert-Bold.otf") format("truetype");
  font-weight: bold;
}

body {
  text-underline-offset: 4px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
